import React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Footprint from '@app/components/UI/Footprint/Footprint';

import '@app/scss/components/recommendations.scss';

interface RecommendationsProps {
	items?: Array<RecommendationsItemProps>;
}

interface RecommendationsItemProps {
	link: string;
	images: Array<string | { src, width, height }>;
	text: string | React.ReactNode;
}

const RecommendationsItem: React.FC<RecommendationsItemProps> = ({ link, text, images }) => {
	return <a href={link} target="_blank" rel="nofollow noreferrer">
		<div className="recommendation-item">
			<div className="recommendation-item__images">
				{images.slice(0, 4).map((image, i) => <ImageLazy
					key={`${i}${image}`}
					alt="recommendations"
					width={typeof image === 'string' ? 78 : image.width}
					height={typeof image === 'string' ? 102 : image.height}
					src={typeof image === 'string' ? image : image.src}
				/>)}
			</div>
			<h3 className="text-center">
				{text}
			</h3>
		</div>
	</a>;
};

const defaultItems = [
	{
		items: [
			'/images/recommendations/image 375.png',
			'/images/recommendations/image 376.png',
			'/images/recommendations/image 359.png',
			'/images/recommendations/image 358.png',
		],
		text: 'Books for Vets and Pet Owners',
		link: 'https://www.amazon.com/shop/drmarygardner?listId=33DL580L38SNW',
	},
	{
		items: [
			{
				src: '/images/recommendations/image 377.png',
				width: 53,
				height: 67,
			},
			{
				src: '/images/recommendations/image 378.png',
				width: 65,
				height: 69,
			},
			{
				src: '/images/recommendations/image 379.png',
				width: 69,
				height: 66,
			},
			{
				src: '/images/recommendations/image 380.png',
				width: 96,
				height: 62,
			},
		],
		text: 'Mobility Support Products for Pets',
		link: 'https://www.amazon.com/shop/drmarygardner?listId=WVV9FBK4Y6MV',
	},
	{
		items: [
			{
				src: '/images/recommendations/image 381.png',
				width: 83,
				height: 45,
			},
			{
				src: '/images/recommendations/image 383.png',
				width: 97,
				height: 73,
			},
			{
				src: '/images/recommendations/image 382.png',
				width: 100,
				height: 43,
			},
		],
		text: 'Grey Muzzle Pet Supplies',
		link: 'https://www.amazon.com/shop/drmarygardner?listId=T3C37DG4M1EC',
	},
	{
		items: [
			{
				src: '/images/recommendations/image 384.png',
				width: 71,
				height: 71,
			},
			{
				src: '/images/recommendations/image 385.png',
				width: 63,
				height: 62,
			},
			{
				src: '/images/recommendations/image 386.png',
				width: 61,
				height: 62,
			},
			{
				src: '/images/recommendations/image 387.png',
				width: 80,
				height: 58,
			},
		],
		text: 'Veterinary Clinic Items',
		link: 'https://www.amazon.com/shop/drmarygardner?listId=3M8BIO2A1EAEO',
	},
	{
		items: [
			{
				src: '/images/recommendations/image 389.png',
				width: 67,
				height: 69,
			},
			{
				src: '/images/recommendations/image 388.png',
				width: 55,
				height: 68,
			},
			{
				src: '/images/recommendations/image 390.png',
				width: 68,
				height: 79,
			},
		],
		text: <>
			Fun Clothes and Items for Grey
			{' '}
			<br />
			{' '}
			Muzzle Lovers
		</>,
		link: 'https://www.amazon.com/shop/drmarygardner?listId=2EP4R6O73TMRK',
	},
	{
		items: [
			'/images/recommendations/image 391.png',
			'/images/recommendations/image 392.png',
			'/images/recommendations/image 393.png',
			'/images/recommendations/image 394.png',
		],
		text: 'Memorial Items',
		link: 'https://www.amazon.com/shop/drmarygardner?listId=1CGM836USYBAH',
	},
];

const Recommendations: React.FC<RecommendationsProps> = ({ items = defaultItems }) => {
	return <div className="container">
		<div className="recommendations text-center">
			<h2>
				Product Recommendations
			</h2>
			<div className="h4">
				Explore Dr Mary’s curated selection of products designed to assist pet parents in managing their older
				pet's ailments.
				From mobility aids to products to help with incontinence and to specialized books to support the family.
				Whether you're seeking practical solutions for your beloved companion at home or valuable tools for your
				veterinary practice,
				you'll find a range of trusted recommendations below.
			</div>
			<div className="recommendations-items">
				{items.map((item, i) => <RecommendationsItem
					key={i}
					link={item.link}
					images={item.items}
					text={item.text}
				/>)}
			</div>
			<a href="https://www.amazon.com/shop/drmarygardner" className="btn btn-primary" target="_blank" rel="nofollow noreferrer">
				<Footprint />
				Click here to browse
			</a>
		</div>
	</div>;
};

export default Recommendations;
