import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Card from '@app/components/UI/Card/Card';
import Footprint from '@app/components/UI/Footprint/Footprint';
import { BlogPost } from '@app/objects/Education';

interface EducationProps {
	posts: Array<BlogPost> | undefined;
}

const Education: React.FC<EducationProps> = ({ posts }) => {
	return posts ? (
		<div className="education">
			<div className="container">
				<Card
					imageLeftSide={false}
					image={posts[2] && (
						<NavLink to={`education/${posts[2].category.urlPath}/${posts[2].urlPath}`}>
							<div className="mobile-hidden">
								<ImageLazy alt={posts[2].title} src={posts[2].avatar} />
								<div className="h3">{posts[2].title}</div>
							</div>
						</NavLink>
					)}
					text={
						<>
							<div className="is-relative">
								<ImageLazy className="cat-pen" alt="cat pen" src="/images/CatP15.png" width={167} height={184} />
								<h2>Education</h2>
								<p className="h4">
									Explore a plethora of educational articles to help you with compassionate care for your aging pet.
								</p>
								<NavLink to="/education" className="btn btn-primary mobile-hidden">
									<Footprint />
									All publications
								</NavLink>
							</div>
							{posts[0] && (
								<div className="clearfix">
									<div className="row">
										<NavLink to={`education/${posts[0].category.urlPath}/${posts[0].urlPath}`}>
											<div className="col-sm-6">
												<ImageLazy alt={posts[0].title} src={posts[0].avatar} />
												<div className="h3">{posts[0].title}</div>
											</div>
										</NavLink>
										{posts[1] && (
											<NavLink to={`education/${posts[1].category.urlPath}/${posts[1].urlPath}`}>
												<div className="col-sm-6 mobile-hidden">
													<ImageLazy alt={posts[1].title} src={posts[1].avatar} />
													<div className="h3">{posts[1].title}</div>
												</div>
											</NavLink>
										)}
									</div>
								</div>
							)}
							<NavLink to="/education" className="btn btn-primary show-mobile">
								<Footprint />
								All publications
							</NavLink>
						</>
					}
				/>
			</div>
		</div>
	) : (
		<></>
	);
};

export default Education;
