import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Footprint from '@app/components/UI/Footprint/Footprint';
import Card from '@app/components/UI/Card/Card';
import SelectedText from '@app/components/UI/SelectedText';

const AboutMaryGardner: React.FC = () => {
	return <div className="about-mary-gardner is-relative">
		<ImageLazy
			className="treat"
			alt="treat"
			src="/images/treat.png"
			width={402}
			height={314}
		/>
		<div className="container">
			<Card
				imageLeftSide={false}
				image={<ImageLazy
					width={677}
					height={578}
					alt="hugs"
					src="/images/Group.png"
				/>}
				text={<>
					<h3>
						<SelectedText
							text="About Dr."
						/>
						Mary Gardner
					</h3>
					<p className="h4">
						A dynamic and engaging keynote speaker, Dr. Mary Gardner is able to draw on a wide
						range of career experiences and life lessons to educate and motivate diverse audiences.
						Her presentations cover a variety of topics, and encourage both professional and personal
						growth within the veterinary industry and beyond.
					</p>
					<NavLink
						to="/about"
						className="btn btn-primary"
					>
						<Footprint />
						Read more
					</NavLink>
				</>}
			/>
		</div>
	</div>;
};

export default AboutMaryGardner;
