import React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Card from '@app/components/UI/Card/Card';
import '@app/scss/components/youtubeBlock.scss';

interface Props {
	image?: string;
	title?: React.ReactNode;
	text?: string;
	link?: string;
	iframe?: React.ReactNode | HTMLElement;
}

const Youtube: React.FC<Props> = (props) => {
	const {
		image = '/images/image 367.png',
		text = `Explore Dr Mary’s YouTube channel, where you'll find a treasure trove of videos dedicated to helping you ensure a happy and 
			healthy life for your older pets.`,
		title = 'Youtube',
	} = props;

	return (
		<div className="container">
			<div className="youtube">
				<Card
					imageLeftSide={false}
					image={<>
						{props.iframe
							? props.iframe
							: (
								<ImageLazy
									className="is-relative"
									style={{ zIndex: 3 }}
									alt="youtube video"
									src={image}
									width={769}
									height={433}
								/>
							)
						}
						<ImageLazy
							alt="cat"
							className="cat"
							width={457}
							height={514}
							src="images/cat.png"
						/>
						<a
							href={props.link}
							className="youtube-link is-relative"
							target="_blank"
							rel="nofollow noreferrer"
						>
							<span>
								<svg
									className="youtube-arrow"
									xmlns="http://www.w3.org/2000/svg"
									width="221"
									height="129"
									viewBox="0 0 221 129"
									fill="none"
								>
									<path
										d="M1.29786 0.926711C-1.84665 25.5115 5.69923 50.753 21.2221 70.2657C31.9905 83.6185 46.6607
										91.186 61.7693 85.5565C74.4552 107.569 98.4539 122.121 125.36 110.816C129.205 109.093 133.058
										106.861 136.153 104.111C136.659 104.372 136.91 104.63 137.416 104.891C159.126 119.157 185.622
										119.019 209.174 110.714C205.283 115.738 201.65 120.511 197.759 125.535C195.944 127.795 199.485
										129.622 201.286 128.377C205.92 124.887 221.584 115.965 220.158 108.582C218.729 101.452 203.012
										95.9016 197.706 92.7809C195.18 91.2223 192.831 95.252 195.36 96.5568C198.392 98.3763 206.496 101.79
										210.777 105.404C192.078 112.761 171.153 115.262 152.15 107.889C148.096 106.309 143.797 103.964 139.506
										101.111C149.072 91.0882 154.109 76.9398 150.476 63.4323C146.347 48.9024 126.657 35.9332 114.98
										51.2587C102.528 67.3351 119.024 90.1621 132.619 101.777C115.36 115.501 89.4331 111.332 74.1013
										96.3921C70.5814 93.0424 67.5815 88.9382 65.0946 84.5872C67.4046 83.3498 69.7181 81.8586 71.7804
										80.11C85.1768 69.3787 97.8018 40.8634 88.0982 24.2247C78.3981 7.33203 58.8535 20.5168 54.5875
										34.1681C49.7873 49.5894 52.0869 67.3946 59.7777 82.228C53.633 84.4276 46.749 84.8396 39.9145
										81.6976C30.0377 77.4977 22.538 67.2372 17.0583 58.2743C6.60115 40.8633 2.79805 21.2598 3.33193
										1.20894C3.60784 -0.310633 1.57023 -0.339014 1.29786 0.926711ZM115.854 61.6809C118.889 44.9655
										136.708 45.9753 143.67 58.2595C146.408 62.8679 147.345 68.7207 147.271 74.0517C146.889 83.1868
										142.435 92.0114 135.976 98.523C123.881 88.9602 113.367 75.6109 115.854 61.6809ZM58.3691
										37.0137C58.9209 33.9746 60.2368 30.946 61.8004 28.4288C67.2763 19.3645 74.928 18.7093 84.7623
										25.9555C87.2563 29.7988 87.4298 35.641 87.115 39.9529C86.4713 49.5923 82.4988 60.4548 77.037
										68.5037C73.4005 73.5311 68.7523 78.0366 63.3647 80.7546C56.4203 67.201 54.3542 50.9225 58.3691 37.0137Z"
										fill="#473373"
									/>
								</svg>
								<svg
									className="play-arrow"
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="26"
									viewBox="0 0 20 26"
									fill="none"
								>
									<path d="M19.291 13.0007L0.322287 25.1257L0.322288 0.875741L19.291 13.0007Z" fill="white" />
								</svg>
							</span>
							<br />
							{' '}
							Subscribe
							{' '}
							<br />
							to my Youtube channel
						</a>
					</>}
					text={<>
						<h2>
							{title}
						</h2>
						<p className="h4">
							{text}
						</p>
					</>}
				/>
			</div>
		</div>
	);
};

export default Youtube;
