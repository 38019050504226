import React from 'react';

const ScrollBlockDown = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="45" height="91" viewBox="0 0 45 91" fill="none">
			<path
				d="M9.99942 90.7971C12.6029 88.8491 14.5556 85.9271 16.3455 83.0051C17.3218 81.5442 18.1354 79.9208 18.949 78.1352C18.949
				77.9729 19.1117 77.9728 19.1117 77.8105C19.7626 76.3495 17.1591 75.2132 16.5082 76.6742C16.02 77.4859 15.5319 78.7845 14.881 80.0831C13.9047
				81.8688 12.6029 83.4921 10.9757 84.9531C8.20951 72.7782 8.20952 59.954 11.9521 47.9414C12.2775 46.9674 12.6029 45.8311 12.9284 44.8571C13.7419
				45.8311 14.5555 46.805 15.3691 47.779C20.2507 52.8113 26.9222 57.1943 34.2446 56.058C40.5907 55.084 45.3096 50.214 44.9841 43.5584C44.3333
				30.8965 27.5731 25.2149 17.9727 32.0328C15.3692 33.8185 13.4165 36.4158 11.9521 39.3378C10.9758 37.8768 10.1621 36.2535 9.34855
				34.7925C4.30424 24.2409 3.65337 12.2283 6.09416 0.865043C6.25688 0.0533817 4.95509 -0.433635 4.79237 0.540358C1.37526 14.5009
				3.00249 29.9225 10.9757 41.7728C9.02311 46.3181 8.04676 51.3504 7.23316 55.571C5.28053 66.2849 5.62075 77.5502 8.69768 87.5504C5.97977
				87.0985 2.99285 80.7782 2.79281 78.1839C2.46737 76.3982 -0.298877 77.0476 0.0265621 78.9956C0.514721 82.8915 4.46697 89.0114 8.69768
				90.9594C9.02312 90.9594 9.51126 91.1217 9.99942 90.7971ZM24.4815 32.0328C35.4862 29.5932 49.1955 42.8853 37.9872 51.5127C35.0583
				53.2984 31.9666 53.7854 28.5494 52.9737C24.8069 52.162 21.3898 49.727 18.6235 47.2921C16.8336 45.6687 15.2064 43.8831 13.7419 41.9351C16.02
				37.3898 19.1117 33.1692 24.4815 32.0328Z"
				fill="#5D4493"
			/>
		</svg>
	);
};

export default ScrollBlockDown;
