import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Card from '@app/components/UI/Card/Card';
import Footprint from '@app/components/UI/Footprint/Footprint';
import SelectedText from '@app/components/UI/SelectedText';
import Line2 from '@app/components/UI/Lines/Line2';

const WhyTheLoveOfOldAnimals: React.FC = () => {
	return <div className="bg-white-opacity">
		<div className="container why-the-love">
			<Card
				image={<ImageLazy
					src="/images/holiday.png"
					alt="holiday"
					width={565}
					height={448}
				/>}
				text={<>
					<h3>
						Why the love of
						<SelectedText
							text=" old animals?"
							line={<Line2 />}
						/>
					</h3>
					<p className="h4">
						There is nothing better to Dr. Mary than a skinny old cat or wobbly geriatric dog.
						The elderly pet population is a growing demographic and owning a super-senior brings
						a different set of responsibilities, challenges and emotional strains. Dr. Mary has had her
						fair share of geriatric pets throughout her life. From a kidney failure cat to a dog
						with major mobility issues, and many in between. She knows firsthand what struggles families
						go through when dealing with the aging pet. It is her professional goal to help
						the older pet population and help the families that care so much about them.
					</p>
					<NavLink className="btn btn-primary" to="/geriatric-pets">
						<Footprint />
						What is a geriatric pet?
					</NavLink>
				</>}
			/>
		</div>
	</div>;
};

export default WhyTheLoveOfOldAnimals;
