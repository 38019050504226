import * as React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import LastTab from '@app/components/Pages/Books/LastTab';
import AboutMaryGardner from '@app/components/Pages/Home/AboutMaryGardner';
import Education from '@app/components/Pages/Home/Education';
import HerPurpose from '@app/components/Pages/Home/HerPurpose';
import PetParents from '@app/components/Pages/Home/PetParents';
import Recommendations from '@app/components/Pages/Home/Recommendations';
import WhyTheLoveOfOldAnimals from '@app/components/Pages/Home/WhyTheLoveOfOldAnimals';
import Youtube from '@app/components/Pages/Home/Youtube';
import Background from '@app/components/UI/Background/Background';
import Card from '@app/components/UI/Card/Card';
import Footprint from '@app/components/UI/Footprint/Footprint';
import HelmetProvider from '@app/components/UI/Helmet/HelmetCustom';
import Line3 from '@app/components/UI/Lines/Line3';
import SelectedText from '@app/components/UI/SelectedText';
import SpeakingForm from '@app/components/UI/SpeakingForm/SpeakingForm';
import CustomSwiper from '@app/components/UI/Swiper/CustomSwiper';
import Tabs, { Tab } from '@app/components/UI/Tabs/Tabs';
import ScrollBlockDown from '@app/imgs/ScrollBlockDown';
import { Book, BookCategory } from '@app/objects/BookCategory';
import { BlogPost } from '@app/objects/Education';
import '@app/scss/components/speakingBlock.scss';
import '@app/scss/pages/home.scss';

const videos = [
	{
		title: 'Knowing When is Time to Say Goodbye to a Pet',
		link: 'https://www.youtube.com/watch?v=MJL4itdJn0s',
		img: '/images/slide_1.jpg',
	},
	{
		title: 'Dr. Mary: Help – My Dog Has Cushings – Now What? (Feat. Dr. Tatjana Mirkovic)',
		link: 'https://www.youtube.com/watch?v=GyRIFfVMg7M',
		img: '/images/slide_2.jpg',
	},
	{
		title: 'Dr. Mary: Helping Dogs with GOLPP (Feat. Dr. Bryden Stanley)',
		link: 'https://www.youtube.com/watch?v=VxT4lDbqXQw',
		img: '/images/slide_3.jpg',
	},
	{
		title: 'Dr Mary helps a family in Orange County California say goodbye to their Labrador Roxy',
		link: 'https://www.youtube.com/watch?v=_5CMVzdJUXA',
		img: '/images/slide_4.jpg',
	},
	{
		title: 'Dr. Mary: How to Say Goodbye On a Good Day!',
		link: 'https://www.youtube.com/watch?v=Y2BHOL9g5lM',
		img: '/images/slide_5.jpg',
	},
	{
		title: 'Dr. Mary: Canine Arthritis (Feat. Dr. Kristin Kirkby Shaw)',
		link: 'https://www.youtube.com/watch?v=WnO_laLXs-o',
		img: '/images/slide_6.jpg',
	},
	{
		title: 'Dr. Mary: Why Is My Dog’s Nose So Dry?',
		link: 'https://www.youtube.com/watch?v=uLcAVC5XwVQ',
		img: '/images/slide_7.jpg',
	},
];

interface BookCardProps {
	book: Book;
	path: string;
}

const BookCard: React.FC<BookCardProps> = ({ book, path }) => {
	return book ? (
		<Card
			className="card_image-shadow"
			image={
				<>
					<svg xmlns="http://www.w3.org/2000/svg" width="452" height="397" viewBox="0 0 452 397" fill="none">
						<path
							d="M442.418 68.7888C480.826 140.96 387.688 282.428 336.318 344.14C303.072 384.079 268.179 416.07 168.148
							381.269C-48.9587 305.736 -1.27606 145.607 22.6218 73.0445C46.5197 0.481748 106.617 -11.0724 177.881
							10.5212C234.892 27.796 279.267 28.0787 294.328 26.0607C327.688 10.232 404.01 -3.38273 442.418 68.7888Z"
							fill="#FFDD60"
						/>
					</svg>
					<ImageLazy className="image-dog" alt={`Book - ${book.name}`} src={book.avatar} />
				</>
			}
			text={
				<>
					{book.shortDescription && <div className="short-description" dangerouslySetInnerHTML={{ __html: book.shortDescription }} />}
					<NavLink to={`/books/${path}`}>
						<button className="btn btn-primary">
							<Footprint />
							More books from this category
						</button>
					</NavLink>
				</>
			}
		/>
	) : (
		<div className="h3 text-center">Books in this section are temporarily unavailable.</div>
	);
};

const Home: React.FC = () => {
	const [page, setPage] = React.useState<any>();
	const [posts, setPosts] = React.useState<Array<BlogPost>>();
	const [bookTabs, setBookTabs] = React.useState<Array<Tab>>();

	React.useEffect(() => {
		if (page) {
			setPosts(page.blogPosts);

			const items = page.items.list.map((item: BookCategory) => {
				return {
					key: item.urlPath,
					label: item.name,
					children: <BookCard book={item.books[0]} path={item.urlPath} />,
				};
			});

			items.push({
				key: 'recommended-books',
				label: 'Recommended books',
				children: <LastTab />,
			});

			setBookTabs(items);
		}
	}, [page]);

	return (
		<HelmetProvider title="Dr. Mary Gardner" ttl={600000} onChangePage={setPage}>
			<div className="home-page is-relative pages">
				<Background />
				<div className="is-relative first-screen text-center">
					<picture>
						<source media="(max-width: 1100px)" srcSet="/images/first-screen-1-m.png" />
						<source media="(min-width: 1101px)" srcSet="/images/first-screen-1.png" />
						<img className="first-screen-1" alt="image-1" src="/images/first-screen-1.png" />
					</picture>
					<img className="first-screen-2" alt="image-2" src="/images/children-page-mary.png" />
					<div className="container">
						<p className="h3">Dr. Mary Gardner</p>
						<p className="h4">Veterinarian, innovator, professional speaker</p>
						<h1>”Making the lives of older pets and the people who love them better!”</h1>
						<div className="scroll-down">
							<p className="h4">Scroll down</p>
							<ScrollBlockDown />
						</div>
					</div>
				</div>
				{bookTabs && (
					<div className="bg-white-opacity books">
						<div className="container">
							<h2 className="text-center">Books</h2>
							<Tabs items={bookTabs} />
						</div>
					</div>
				)}
				<AboutMaryGardner />
				<WhyTheLoveOfOldAnimals />
				<PetParents />
				<HerPurpose />
				{posts && <Education posts={posts} />}
				<Youtube
					link="https://www.youtube.com/channel/UCp9kmv63ZbC8t_movGOPj4Q"
					iframe={<iframe title="youtube-iframe-home" src="https://www.youtube.com/embed/hhgvehArtOo?si=y5qO2xiEWmNH2tUz" />}
				/>
				<div className="bg-white-opacity" style={{ paddingTop: 0 }}>
					<CustomSwiper events={false} videos={videos} />
					<div className="container">
						<div className="speaking">
							<Card
								image={
									<>
										<h2 className="speaking-h2">
											<SelectedText
												text="Speaking"
												line={<Line3 />}
											/>
										</h2>
										<h3>Energetic, engaging and inspiring keynotes and workshops for veterinary teams and pet owners!</h3>
										<p className="h4">
											Dr. Mary speaks for professional and lay audiences on geriatric medicine, assessing quality of
											life, hospice, and end of life care. She has given over a dozen keynote addresses on
											entrepreneurship, leadership, motivation and finding your niche. She has spoken at VMX, AVMA, WVC,
											Fetch, state VMA’s, IAAHPC, professional sales meetings, veterinary schools and animal hospitals
											around the world.
										</p>
										<div className="is-relative">
											<ImageLazy alt="image" width={729} height={410} src="/images/img2.png" />
										</div>
									</>
								}
								text={
									<>
										<SpeakingForm />
									</>
								}
							/>
						</div>
					</div>
				</div>
				<Recommendations />
			</div>
		</HelmetProvider>
	);
};

export default Home;
