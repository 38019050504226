import React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Card from '@app/components/UI/Card/Card';
import SelectedText from '@app/components/UI/SelectedText';
import Footprint from '@app/components/UI/Footprint/Footprint';
import Line2 from '@app/components/UI/Lines/Line2';

const HerPurpose: React.FC = () => {
	return <div className="bg-white-opacity her-purpose">
		<div className="container">
			<Card
				image={<ImageLazy
					alt="Her Purpose"
					src="/images/HerPurpose.png"
					width={348}
					height={402}
				/>}
				text={<>
					<h2>
						<SelectedText
							line={<Line2 />}
							text="Her Purpose -"
						/>
						&nbsp;Grey Muzzles
					</h2>
					<p className="h4">
						It’s a simple fact — pets just don’t live as long as they should! From the
						first sign of a grey muzzle we start to worry about the end. But our furry
						family members can still have a good quality of life and have a wonderful time
						as a geriatric pet!
					</p>
					<NavLink to="/about">
						<button className="btn btn-primary">
							<Footprint />
							Read more
						</button>
					</NavLink>
				</>}
			/>
		</div>
	</div>;
};

export default HerPurpose;
