import React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import Tabs from '@app/components/UI/Tabs/Tabs';
import Card from '@app/components/UI/Card/Card';
import Line2 from '@app/components/UI/Lines/Line2';

interface ItemProps {
	text: React.ReactNode;
	title?: string;
}

const Item: React.FC<ItemProps> = ({ title, text }) => {
	const textWithQuotes = `“${title}”`;
	return <Card
		imageLeftSide={false}
		image={<ImageLazy
			width={610}
			height={532}
			alt="Pet Parents"
			src="/images/PetParents.png"
		/>}
		text={<div className="h4">
			{title && <h3>{textWithQuotes}</h3>}
			{text}
		</div>}
	/>;
};

const items = [
	{
		key: 'Assessing life quality',
		label: 'Assessing life quality',
		children: <Item
			title="There really is never a ‘best’ time..."
			text={<>
				<p>
					The most common question a family will ask when facing end of life is ‘how will I know it is time to
					say goodbye’. As someone who has helped thousands navigate those difficult decisions, I can tell you
					that there is no one perfect answer. Many things effect this decision from the pet’s personality,
					the families ability to care for the pet, and most importantly the symptoms that the pet is
					struggling with.
				</p>
				<p>
					All of those combined may change when the best time is. (there really is never a ‘best’ time. but
					there is a worst time and that is when you haven’t prepared and are rushing to the emergency room).
					{' '}
					<a className="link" href="https://www.youtube.com/watch?v=MJL4itdJn0s&t" target="_blank" rel="nofollow noreferrer">
						This video may be helpful if you are wondering when is time for your own pet.
					</a>
				</p>
			</>}
		/>,
	},
	{
		key: 'Caregiver burden',
		label: 'Caregiver burden',
		children: <Item
			title="Pet owners may not always know when it’s time..."
			text={<>
				<p>
					Not until I dealt with the struggles of caring for my own geriatric pet, did I fully appreciate what
					caregiver burden was really all about. You love your pet, you will do what you need to do – but it’s hard physically and emotionally.
				</p>
				<p>
					With our pets we also have the burden of deciding to euthanize when appropriate. And that in itself
					is a heart wrenching decision. Quality of life is a very subjective thing and pet owners may not
					always know when it’s time. Pet owners do know their pets best but they still may need guidance.
					And they may end up with guilt if they feel they’ve waited too long.
					It’s the one conversation that we veterinarians have the most with families seeking end of life services.
				</p>
			</>}
		/>,
	},
	{
		key: 'Symptoms of Pet Caregiver Burnout',
		label: 'Symptoms of Pet Caregiver Burnout',
		children: <Item
			text={<>
				<ul>
					<li>Withdrawal from friends, family, and other loved ones</li>
					<li>Loss of interest in activities previously enjoyed</li>
					<li>Feeling blue, irritable, hopeless, and helpless</li>
					<li>Changes in appetite, weight, or both</li>
					<li>Changes in sleep patterns</li>
					<li>Getting sick more often</li>
					<li>Feelings of wanting to hurt yourself or the person for whom you are caring</li>
					<li>Emotional and physical exhaustion</li>
					<li>Irritability</li>
				</ul>
				<p>
					Two reasons why caregivers burn out is because they place unreasonable burdens upon themselves, in
					part because they see providing care as their exclusive responsibility and they cannot recognize
					when they are
					suffering burnout and eventually get to the point where they cannot function effectively. They may
					even become sick themselves.
				</p>
			</>}
		/>,
	},
	{
		key: 'What can help?',
		label: 'What can help?',
		children: <Item
			text={<>
				<ul className="unique-makers">
					<li>
						<strong>Mastery:</strong>
						{' '}
						Pet parents need to feel empowered and knowledgeable.
						Educating them not only on the disease but also the ways to manage the symptoms is vital.
					</li>
					<li>
						<strong>Coping Strategies:</strong>
						{' '}
						Speaking to a counselor is something many would not even consider but they can be a massive
						resource for the families.
						{' '}
						<a className="link" href="https://www.caregiver.org/" target="_blank" rel="nofollow noreferrer">
							Caregiver.org
						</a>
						{' '}
						is a great reference site.
						{' '}
						<a className="link" href="https://www.petcaregiverburden.com/" target="_blank" rel="nofollow noreferrer">
							Petcaregiverburden.org
						</a>
						{' '}
						is also a wonderful site.
					</li>
					<li>
						<strong>Social Support:</strong>
						{' '}
						There are many illness specific groups (locally and on social media) and recruit
						family and friends to help – it takes a village to care for a sick pet.
					</li>
					<li>
						<strong>Respite Care:</strong>
						{' '}
						This type of care is common place in human medicine and can be a massive
						help to a family. Veterinary technicians can play a huge role in this niche.
					</li>
				</ul>
			</>}
		/>,
	},
];

const PetParents: React.FC = () => {
	return <div className="container pet-parents">
		<h2 className="text-center">
			Pet Parents
		</h2>
		<Tabs items={items} line={<Line2 />} />
	</div>;
};

export default PetParents;
